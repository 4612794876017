import { Language } from "@cyna/common/constants"
import { countries } from "@cyna/components/base/phone-input/countries"

export const LANGUAGE_LOCAL_STORAGE_KEY = "LANGUAGE"

export const LANGUAGE_TO_ISO2: Record<
  Language,
  (typeof countries)[number]["iso2"]
> = {
  en: "US",
  fr: "FR",
  nl: "NL",
  zh: "CN",
} as const

export const LOCAL_STORAGE_LANGUAGE_KEY = "cyna_current_user_language"

export const LOCAL_STORAGE_SCOPE_KEY = "cyna_current_user_current_scope"
